import { gql } from '@urql/core';
import methods from '@/utils/methods'

type roles = 'no-roles'|'user'|'staff'|'instanceAdmin'|'support' 

// Get user by uid
export const getAccount = (role: roles)=> {
  switch (role) {
    case 'no-roles':
      return gql`
      query users($firebaseID: String) {
        users(where: {firebaseID: {_eq: $firebaseID}}) {
          id
          firebaseID
          name
          avatar
          private {
            email
            phone
          }
        }
      }`
    default:
      return gql`
        query users($firebaseID: String) {
          users(where: {firebaseID: {_eq: $firebaseID}}) {
            id
            firebaseID
            name
            avatar
            private {
              email
              phone
            }
            admins(where: {instance: {active: {_eq: true}}}) {
              readOnly
              instance {
                id
                name
                latitude
                longitude
                attributes
                config {
                  events
                }
                contractTemplates(order_by: {createdAt: desc}) {
                  id
                  name
                  frequency
                  duration
                  startDate
                }
                customer {
                  id
                  name
                }
                parcel_tags(distinct_on: [name, contractTemplateID], order_by: {name: asc, contractTemplateID:asc}) {
                  name
                }
                route_tags(distinct_on: [name], order_by: {name: asc}) {
                  name
                }
              }
            }
            favorite_routes {
              id
              route {
                name
                id
              }
            }
            parcels(where: {parcel: {instance: {active: {_eq: true}}}}) {
              status
              parcel {
                instance {
                  id
                  name
                  latitude
                  longitude
                  attributes
                  routes(where: {public: {_eq: true}}) {
                    id
                    public
                    name
                    stateID
                    stops(where: {parcelID: {_is_null: true}}) {
                      stop {
                        id
                        name
                        latitude
                        longitude
                      }
                    }
                    staff {
                      staff {
                        name
                        phone
                      }
                    }
                    vehicle {
                      name
                    }
                  }
                  config {
                    events
                  }
                  customer {
                    name
                  }
                }
                extraPopulation {
                  parcelID
                  active
                }
                id
                name
                firstName
                lastName
                documentID
                avatar
                instanceID
                stops {
                  id
                  address
                  place
                  placeDetails
                  hint
                  latitude
                  longitude
                  instanceID
                  parcelID
                  country
                  administrativeArea
                  locality
                  postalCode
                  routes {
                    id
                    instanceID
                    routeID
                    stopID
                    cancelations(where: {_and: [{endDate: {_gte:"${methods.getDate(new Date())}"}}, {rescinded: {_eq: false}}]}) {
                      id
                      startDate
                      endDate
                      stopID
                      routeID
                      route {
                        name
                      }
                    }
                    route {
                      id
                      name
                      stateID
                      staff {
                        staff {
                          name
                          phone
                        }
                      }
                      vehicle {
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `
  }
}

export const getInstances = gql`
  query instances {
    instances(where: {active: {_eq: true}}, order_by: {name:asc}) {
      id
      name
      latitude
      longitude
      attributes
      config {
        events
        fixedTags
      }
      contractTemplates(order_by: {createdAt: desc}) {
        id
        name
        frequency
        duration
        startDate
      }
      customer {
        id
        name
      }
      parcel_tags(distinct_on: [name], order_by: {name: asc}) {
        name
      }
      route_tags(distinct_on: [name], order_by: {name: asc}) {
        name
      }
    }
  }
`