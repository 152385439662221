import { createApp } from 'vue'
import App from './App.vue'
import * as Sentry from "@sentry/vue"
import { initConfig, vfmPlugin } from '@isagaco/vuesaga'
import './index.css'
import '@isagaco/vuesaga/style.css'

import axios from 'axios'

axios.interceptors.request.use(async function (config) {
  if (!!state.value.jwtRefresh && new Date(state.value.jwtRefresh).getTime() > Date.now()) {
    config.headers['Authorization'] = `Bearer ${state.value.jwt}`
  } else {
    const {token:jwt, jwtRefresh}= await firebaseTools.getCurrentUserJWT(true)
    config.headers['Authorization'] = `Bearer ${jwt}`
    state.value.jwt = jwt
    state.value.jwtRefresh = jwtRefresh
  }
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

let baseURL
if (window.location.hostname === 'localhost') {
  baseURL = 'http://localhost:5001/buseticas-7f9fd/us-central1/api/'
} else {
  baseURL = import.meta.env.DEV
  ? `https://${import.meta.env.VITE_TAIL}/buseticas-7f9fd/us-central1/api/`
  : 'https://us-central1-buseticas-7f9fd.cloudfunctions.net/api/'
}
axios.defaults.baseURL = baseURL


Promise.allSettled = Promise.allSettled || ((promises) => Promise.all(
  promises.map(p => p
      .then(value => ({
          status: "fulfilled",
          value
      }))
      .catch(reason => ({
          status: "rejected",
          reason
      }))
  )
));

import urql from '@urql/vue'
import { urqlConfig } from './services/hasura'
import { initFirebase, firebaseTools } from './services/firebase'
import { state } from './services/localStorage'

import router from './router'
import { createPinia } from 'pinia'

let vueInstance: any = null
let ref:any = null

async function updateToken (hardReset: boolean = false) {
  const shouldRefresh = !!state.value.jwtRefresh && new Date(state.value.jwtRefresh).getTime() < Date.now()
  const {token:jwt, jwtRefresh}= await firebaseTools.getCurrentUserJWT(shouldRefresh || hardReset)
  state.value.jwt = jwt
  state.value.jwtRefresh = jwtRefresh
}

async function checkFirebaseUser () {
  // Check if cordova
  await initFirebase()
  initConfig()
  if (await firebaseTools.getCurrentUser()) {
    vueInstance = initVue()
  }
  firebaseTools.onIdTokenChanged((user:any) => {
    if (user) {
      if (ref === null) {
        ref = firebaseTools.getRef(`metadata/${user.uid}/refreshTime`)
        firebaseTools.listen(ref, async (snap) => {
          if (state.value.customClaimsUpdate !== snap.val()) {
            state.value.customClaimsUpdate = snap.val()
            updateToken(true)
          } else {
            updateToken()
          }
        })
      } else {
        updateToken(true)
      }
    }
    if (vueInstance === null) {
      vueInstance = initVue()
    }
  })
}
function initVue () {
  const app = createApp(App)
  if (!import.meta.env.DEV) {
    Sentry.init({
      app,
      dsn: "https://ccc1412e986c4eebbf2d5e3b7147827e@o4505398295789568.ingest.sentry.io/4505398297231360",
      integrations: [
        new Sentry.Replay(),
        new Sentry.Integrations.Breadcrumbs({ console: false })
      ],
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
  }
  app.use(router)
  app.use(createPinia())
  app.use(urql, urqlConfig)
  app.use(vfmPlugin)
  app.mount('#app')
}

checkFirebaseUser()